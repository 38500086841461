import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Özgürlüğe Yolculuk
			</title>
			<meta name={"description"} content={"Amacımız size yalnızca en kaliteli bisikletleri sunmak değil, aynı zamanda her yolculuğun unutulmaz olmasını sağlayacak destek ve kaynakları da sunmaktır."} />
			<meta property={"og:title"} content={"Özgürlüğe Yolculuk"} />
			<meta property={"og:description"} content={"Amacımız size yalnızca en kaliteli bisikletleri sunmak değil, aynı zamanda her yolculuğun unutulmaz olmasını sağlayacak destek ve kaynakları da sunmaktır."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Contact>
		<Components.Nav />
		<Section padding="180px 0 180px 0" md-padding="100px 0 100px 0" quarkly-title="Content-12" background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.65) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/2-2.jpg?v=2024-06-07T06:19:36.886Z) 50% 50% /cover repeat scroll padding-box">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="50%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					Kişiler
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white">
					SpeedThrill – Özgürlüğü Sürün
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					SpeedThrill Motosiklet Kiralama ile unutulmaz bir yolculuğa çıkın. Biz bisikletleri sağlıyoruz, siz anıları yaratıyorsunuz. Bizimle yolculuk yapın ve iki tekerlek üzerinde özgürlüğün gerçek özünü keşfedin.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Contacts-3">
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="--headline2"
				color="--darkL1"
				text-align="left"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Sürmeye hazır?
			</Text>
			<Text
				margin="16px 40% 50px 0px"
				font="--base"
				lg-margin="0px 0 50px 0px"
				text-align="left"
				sm-margin="0px 0 35px 0px"
				color="--dark"
				lg-max-width="720px"
			>
				SpeedThrill Motosiklet Kiralama ile açık yolun heyecanını yaşayın. İster yeni arazileri keşfediyor olun ister tanıdık rotalarda geziniyor olun, bisikletlerimiz ve hizmetlerimiz yolculuğunuzu geliştirmek için tasarlanmıştır. Sürüşünüzü ayırtmak ve sürüş özgürlüğünü açığa çıkarmak için hemen bizimle iletişime geçin!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Adres
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						Zerdalilik, Değirmenönü Cd. No:8/B, 07100 Muratpaşa/Antalya, Türkiye
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
					width="50%"
				>
					<Text margin="0px 0px 15px 0px" font="--lead">
						Telefon numarası
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--dark">
						+905326751637
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact>
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Contact>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});